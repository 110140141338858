import { render, staticRenderFns } from "./Competing_B.vue?vue&type=template&id=002ffb81&scoped=true&"
import script from "./Competing_B.vue?vue&type=script&lang=js&"
export * from "./Competing_B.vue?vue&type=script&lang=js&"
import style0 from "./Competing_B.vue?vue&type=style&index=0&id=002ffb81&scoped=true&lang=css&"
import style1 from "./Competing_B.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002ffb81",
  null
  
)

export default component.exports