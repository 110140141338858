<template>
    <div class="Competing_A">
        <div class="rana_cons">
            <div class="rana_con_title">
                竞品分析
            </div>
            <div class="Compet_div clearfix">
                <div class="Com_left fl">
                    <img class="Com_l_img1" src="./../../../assets/img/Tu1.png" alt="">
                    <div class="Com_l_img2" id="leiDaTu" ref="chart">

                    </div>
                </div>
                <div class="Com_right fl">
                    <div class="Com_right_div">
                        <div class="_Com_d_tit clearfix">
                            <div class="fl _Com_one">
                                <span class="fr" style="margin-right:10px">名称</span>
                                <br>
                                <span class="fl" style="margin-left:10px">对标项</span>
                            </div>
                            <div class="_Com_two fl" v-for="(item,index) in form.jpmc" :key="index">
                                <span>{{item.name}}</span>
                            </div>
                            <div class="_Com_two fl">
                                <span>本组计划建设项目</span>
                            </div>
                        </div>
                        <div class="_Com_d_tit clearfix" v-for="(items,indexs) in form.table" :key="indexs">
                            <div class="fl _Com_three" v-for="(citem,cindex) in items" :key="cindex" :class="cindex==0?'_Com_three':'_Com_two'" :title="citem.name">
                                <span v-if="cindex==0">{{citem.name}}</span>
                                <el-input v-else placeholder="请输入内容" maxlength="60" v-model="citem.name" ></el-input>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- 按钮 -->
            <div class="Com_butt_div">
                <div class="Com_button_one" v-preventReClick @click="prevstep()">
                    <span>上一步</span>
                </div>
                <div class="Com_button_two" v-preventReClick @click="nextBotn()">
                    <span>确定</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const echarts = require('echarts');
export default {
    data() {
        return {
            input1:'',
            input2:'',
            input3:'',
            form:{
                jpmc:[],
                radarIndicator:[],
                radarData:[],
                table:[]
            }
        }
    },
    methods: {
        getapp(){
            this.axios.projectS5P2({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    this.form = res.data.data.data
                    this.loadLeida()  //雷达图
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        loadLeida(){
            // 雷达图
            let option = {
                tooltip: {},
	            legend: {},
                radar: {
                    name: {					//端点名字
                        textStyle: {			//文本样式
                            color: "#ffffff"
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(131,141,158,0)'
                        }
                    },
                    // shape: 'circle',
                    center: ['50%', '55%'],
                    radius: 60,
                    startAngle: 90,
                    indicator: this.form.radarIndicator,
                    nameGap: 5,
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: 'rgba(255,0,0,0)' // 图表背景的颜色
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            width: 1,
                            type:'dashed',
                            color: '#000000'    // 设置网格的颜色
                        }
                    }
                },
                series: [{
                    // name: '立意分析雷达图',
                    type: 'radar',
                    symbol: "none", // 拐点的样式，还可以取值'rect','angle'等
                    // symbolSize: 0, // 拐点的大小
                    emphasis: {
                        lineStyle: {
                            width: 2
                        }
                    },
                    itemStyle: {     //此属性的颜色和下面areaStyle属性的颜色都设置成相同色即可实现
                        color: 'RGBA(147, 252, 246, .8)'
                        // lineStyle: {
                        //     color: "rgba(190, 239, 220, 1)",
                        // },
                    },
                    areaStyle: {
                        color: 'RGBA(147, 252, 246, .8)'
                    },
                    data: [
                        {
                            value: this.form.radarData
                        }
                    ]
                }]
            }
            this.$nextTick(function () {    
                    // 雷达图
                    var bar_dv = document.getElementById('leiDaTu')
                    let myChart = echarts.init(bar_dv)
                    myChart.setOption(option)
            });
        },
        prevstep(){
            this.$router.push({name:'Competing_A'})
        },
        nextBotn(){
            var arr1 = this.form
            console.log(arr1)
            this.axios.Sets5({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
                node:'p2',
                value:arr1.table
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push({name:'StudentPage'})
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/student/A/Competing_A.css';
</style>
<style>
._Com_two .el-input__inner{
    width: 100%;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: transparent;
    border-radius: 0;
    border: none;
    padding: 0 10px;
    box-sizing: border-box;
    color: #fff;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
</style>